
import { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface Trip {
    id_trip: string;
    begin_date?: string;
    end_date?: string | null;
    oil_consumption_engine_1: number;
    oil_consumption_engine_2: number;
    oil_consumption_engine_3: number;
    production: number;
    trips_name: number;
    notes: string;
    fk_boats_id_boat: string;
    fk_fishingtypes_id_fishingtype: string;
    boatName: string;
    fishingTypeName: string;
    harvest: string;
    // boats_name: string;
    // fishingtypes_name: string;
}
type TripInput = Omit<Trip, 'id_trip'>

interface TripsProvidersProps {
    children: ReactNode;
}

interface OverviewRequest {
    trip_name?: string | string[];
    boats_name?: string | string[];
    harvest: string;
    fishingtypes_name?: string | string[];
    product_name?: string | string[];
    pages: number;
    limit: number;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface ResponsePropsWithData {
    success: boolean;
    message: string;
    data: any;
}

interface TripsContextData {
    trips: Trip[];
    createTrip: (trip: TripInput) => Promise<ResponseProps>;
    updateTrip: (trip: Trip) => Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
    getTripOrders: (trip: string) => Promise<ResponsePropsWithData>;
    getTripsByBoats: (request: OverviewRequest) => Promise<ResponsePropsWithData>;
    getTripsOverview: (request: OverviewRequest) => Promise<ResponsePropsWithData>;

}

const TripsContext = createContext<TripsContextData>(
    {} as TripsContextData
);


export function TripsProvider({ children }: TripsProvidersProps) {
    const [trips, setTrips] = useState<Trip[]>([]);
    const [refreshOnDelete, setRefreshOnDelete] = useState(0);
    const [tokenAvailable, setTockenAvailable] = useState(false);

    useEffect(() => {
        function handleCustomEvent(event: Event) {
            setTockenAvailable(true);
        }

        window.addEventListener("tokenActive", handleCustomEvent);

        return () => {
            window.removeEventListener("tokenActive", handleCustomEvent);
        };
    }, []);

    useEffect(() => {
        if (!localStorageService.getIdToken()) return;

        api.get('trips').then(response => setTrips(response.data))
    }, [refreshOnDelete, tokenAvailable])

    async function getTripOrders(trip: string) {
        const params = {
            fk_trips_id_trip: trip,
            token: localStorageService.getIdToken(),
        };
        const response = await api.get('/tripsOrders', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar os pedidos relacionadas à viagem.",
            data: [],
        }
    }

    async function getTripsByBoats(request: OverviewRequest) {
        let params = {
            ...request,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };
        const response = await api.get('/tripsbyboat', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar as viagens.",
            data: [],
        }
    }

    async function getTripsOverview(request: OverviewRequest) {
        let params = {
            ...request,
            token: api.defaults.params.token ? api.defaults.params.token : localStorageService.getIdToken(),
        };
        const response = await api.get('/tripsOverview', { params: { ...api.defaults.params, ...params } });
        if (response.status === 200) {
            return {
                success: true,
                message: "",
                data: response.data,
            }
        }
        return {
            success: false,
            message: "Erro ao consultar as viagens.",
            data: [],
        }
    }

    async function updateOnDelete(id: number) {
        const response = await api.post('/deletetrips', { id_trip: id });
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1);
            return {
                success: true,
                message: "Viagem removida com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover viagem."
        }
    }

    async function createTrip(tripsInput: TripInput) {
        if (tripsInput.begin_date === '') {
            delete tripsInput.begin_date;
        }
        // if(tripsInput.end_date === ''){
        //     delete tripsInput.end_date;
        // }
        const response = await api.post('/trips', tripsInput);
        if (response.status === 200) {
            api.get('trips').then(response => setTrips(response.data))
            return {
                success: true,
                message: "Viagem adicionada com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar viagem."
        }

    }

    async function updateTrip(trip: Trip) {
        if (trip.begin_date === '') {
            delete trip.begin_date;
        }
        // if(trip.end_date === ''){
        //    trip.end_date = null;
        // }
        const response = await api.put('/trips', trip);
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1)
            return {
                success: true,
                message: "Viagem editada com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar viagem."
        }
    }

    return (
        <TripsContext.Provider value={{ trips, createTrip, updateTrip, updateOnDelete, getTripOrders, getTripsByBoats, getTripsOverview }}>
            {children}
        </TripsContext.Provider>
    );
}

export function UseTrips() {
    const context = useContext(TripsContext);
    return context;
}