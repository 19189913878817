
import { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface Provider {
    id_providers: string;
    type: string;
    providers_name: string;
    description: string;

}
type ProviderInput = Omit<Provider, 'id_providers'>

interface ProvidersProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface ProvidersContextData {
    providers: Provider[];
    createProvider: (provider: ProviderInput) => Promise<ResponseProps>;
    updateProvider: (provider: Provider) => Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
}

const ProvidersContext = createContext<ProvidersContextData>(
    {} as ProvidersContextData
);


export function ProvidersProvider({ children }: ProvidersProvidersProps) {
    const [providers, setProviders] = useState<Provider[]>([]);
    const [refreshOnDelete, setRefreshOnDelete] = useState(0);
    const [tokenAvailable, setTockenAvailable] = useState(false);

    useEffect(() => {
        function handleCustomEvent(event: Event) {
            setTockenAvailable(true);
        }

        window.addEventListener("tokenActive", handleCustomEvent);

        return () => {
            window.removeEventListener("tokenActive", handleCustomEvent);
        };
    }, []);

    useEffect(() => {
        if (!localStorageService.getIdToken()) return;

        api.get('providers').then(response => setProviders(response.data))
    }, [refreshOnDelete, tokenAvailable])

    async function updateOnDelete(id: number) {
        const response = await api.post('/deleteproviders', { id });
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1);
            return {
                success: true,
                message: "Removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover."
        }
    }

    async function createProvider(providerInput: ProviderInput) {
        const response = await api.post('/providers', providerInput);
        if (response.status === 200) {
            api.get('providers').then(response => setProviders(response.data))
            return {
                success: true,
                message: "Adicionado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar."
        }

    }

    async function updateProvider(provider: Provider) {
        const response = await api.put('/providers', provider);
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1)
            return {
                success: true,
                message: "Editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar."
        }
    }

    return (
        <ProvidersContext.Provider value={{ providers, createProvider, updateProvider, updateOnDelete }}>
            {children}
        </ProvidersContext.Provider>
    );
}

export function UseProviders() {
    const context = useContext(ProvidersContext);
    return context;
}