import React, { useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Header from '../../components/Header';
import {
  Container, Content, Row, RowContent, RowFilters, RowPagination, Title
} from './styles';
import Drawer from "../../components/Drawer";
import Button from "../../components/Button";
import Table from '../../components/Table';
import { UseOrders } from '../../hooks/useOrders';
import AppContext from "../../state/App.context";
import Spinner from '../../components/Spinner';
import { UseUsers } from '../../hooks/useUsers';
import LocalStorageService from '../../services/storage';
import ModalVisualizeOrder from './ModalVisualizeOrder';
import ModalAddOrder from './ModalAddOrder';
import ModalAddOrderStock from './ModalAddOrderStock';
import ModalRemoveOrder from './ModalRemoveOrder';
import ModalEditOrder from './ModalEditOrder';
import ModalChooseColumns from './ModalChooseColumns';
import OrdersFilters from './Filters';
import PaginationComponent from '../../components/Pagination';
import { columns, defaultFilters } from './options';
import ModalAddOrderApportionment from './ModalAddOrderApportionment';
import ModalEditOrderApportionment from './ModalEditOrderApportionment';
import ModalEditOrderStock from './ModalEditOrderStock';
import Searchbar from '../../components/Searchbar';

function Orders() {
  const localStorageService = LocalStorageService();
  const { loading, getFilteredOrders, getOrderDetails } = UseOrders();
  const { getUserPreferences } = UseUsers();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [ordersToShow, setOrdersToShow] = useState([]);
  const [columnsToShow, setColumnsToShow] = useState(columns);
  const [selectedOrder, setSelectedOrder] = useState('');
  const [, setSnack] = useContext(AppContext).snackState;
  const [openModalAddOrder, setOpenModalAddOrder] = useState(false);
  const [openModalAddOrderStock, setOpenModalAddOrderStock] = useState(false);
  const [openModalAddOrderApportionment, setOpenModalAddOrderApportionment] = useState(false);
  const [openModalChooseColumns, setOpenModalChooseColumns] = useState(false)
  const [filters, setFilters] = useState({ ...defaultFilters });
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const location = useLocation();
  const { state } = location;
  const [isStateProcessed, setIsStateProcessed] = useState(false);

  function transformDateFormat(dateString) {
    const originalDate = moment(dateString, 'YYYY-MM-DD');
    const formattedDate = originalDate.format('DD/MM/YYYY');
    return formattedDate;
  }

  let titleToShow = filters.startDate !== "''" && filters.endDate !== "''" ? transformDateFormat(filters.startDate) + '-' + transformDateFormat(filters.endDate) : '';

  function verifyFilterProps(obj) {
    let count = 0;
    for (let prop in obj) {
      if (Array.isArray(obj[prop])) {
        count++;
      }
      if (prop !== 'pages' && prop !== 'limit' && obj[prop] !== "''") {
        count++;
      }
    }
    return count;
  }

  let activeFilters = verifyFilterProps(filters);
  let filterButtonLabel = activeFilters ? "Filtros (" + activeFilters + ")" : "Filtros";

  function formatingColumns(preferences) {
    try {
      let newColumns = [];
      columns && Array.isArray(columns) && columns.forEach((column) => {
        let show = false;
        const index = preferences.findIndex((preference) => preference.name === column.key);
        if (index !== -1) {
          show = preferences[index].status === 'true';
        }
        newColumns.push(
          {
            ...column,
            show,
          }
        );
      });
      return newColumns;
    } catch (err) {
      console.log(err)
    }
  }

  function formatingOrders(ordersCopy) {
    try {
      let formattedOrders = [];
      ordersCopy && Array.isArray(ordersCopy) && ordersCopy.forEach((order) => {
        let status = order.status ? order?.status.includes('Pendente') ? 'Pendente' : 'Executado' : '';
        let last_payment_date = order.payment_date ? order?.payment_date[order?.payment_date.length - 1] : '';
        if (status === 'Pendente' && order?.amount_parcels === 0) {
          last_payment_date = null;
        }
        if (order?.amount_parcels > 0 && order?.qty_parcels_exec === '0.00') {
          last_payment_date = null;
        }
        const last_expiration_date = order.expiration_date ? order?.expiration_date[order?.expiration_date.length - 1] : '';
        let category = 'Padrão';
        if (order?.isapportionment) {
          category = 'Rateio';
        }
        if (order?.isstocked) {
          category = 'Estoque';
        }
        if (order?.issalary) {
          category = 'Salário';
        }
        if (order?.original_order) {
          category = 'Retirada de Estoque';
        }
        if (order.isactive) {
          formattedOrders.push({
            ...order,
            category: category,
            status_name: status,
            payment_date_name: last_payment_date,
            expiration_date_name: last_expiration_date,
            total_order_value_with_discount: parseFloat(order?.total_order_value) - parseFloat(order?.discount),
          })
        }
      });
      return formattedOrders;
    } catch (err) {
      console.log(err);
    }
  }


  async function getInfo() {
    try {
      let formattedFilters = {
        type: filters?.type?.id || "''",
        costCenter: filters?.costCenter?.id || "''",
        costCategory: filters?.costCategory?.id || "''",
        subsidiary: filters?.subsidiary?.id || "''",
        provider: filters?.provider?.id || "''",
        trip: filters?.trip?.id || "''",
        paymentType: filters?.paymentType?.id || "''",
        boat: filters?.boat?.id || "''",
        status: filters?.status?.id || "''",
        startDate: filters.startDate,
        endDate: filters.endDate,
        harvest: filters?.harvest?.id || "''",
        pages: filters?.pages,
        limit: filters?.limit,
      }
      let ordersResponse = await getFilteredOrders(formattedFilters);
      if (ordersResponse.success) {
        let ordersCopy = [...ordersResponse?.data?.data];
        ordersCopy = formatingOrders(ordersCopy);
        setOrdersToShow(ordersCopy);
        setTotalPages(ordersResponse?.data?.pages)
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: ordersResponse.message,
        });
      }
      const userInfo = localStorageService.getUserInfo();
      const id_user = userInfo?.id_user;
      if (id_user) {
        const userPreferences = await getUserPreferences(id_user);
        if (userPreferences.success) {
          const preferences = userPreferences?.data[0].column_preferences;
          if (preferences) {
            const newColumns = formatingColumns(preferences);
            setColumnsToShow(newColumns);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getOrdeRById() {
    try {
      let response = await getOrderDetails(search);
      if (response.success) {
        let formattedOrder = formatingOrders(response.data)
        setOrdersToShow(formattedOrder);
      } else {
        setSnack({
          open: true,
          severity: 'error',
          message: 'Não existe pedido com esse id',
        });
      }

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (search === '' && !state) {
      getInfo();
    } else if (search === '' && isStateProcessed) {
      getInfo();
    } else if (search === '' && !openModalAddOrderStock && !state) {
      getInfo();
    } else if (search === '' && !openModalAddOrder && !state) {
      getInfo();
    } else if (search === '' && !openModalAddOrderApportionment && !state) {
      getInfo();
    } else if (search === '' && !selectedOrder?.open && selectedOrder?.mode !== 'view' && !state) {
      getInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, search, openModalAddOrderStock, openModalAddOrder, openModalAddOrderApportionment, selectedOrder, state, isStateProcessed]);

  useEffect(() => {
    if (search.length > 35) {
      getOrdeRById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (state && !isStateProcessed) {
      setSearch(state.id_order);
      setIsStateProcessed(true);
    }
  }, [state, isStateProcessed]);

  const handleChangePage = (event, value) => {
    setFilters(
      {
        ...filters,
        pages: value,
      }
    )
  };

  function renderTable() {
    if (loading) {
      return (
        <div style={{ width: '100%', height: 200 }}>
          <Spinner width={40} fontSize={14}></Spinner>
        </div>
      )
    } else {
      return (
        <Table
          columns={columnsToShow.filter(element => element.show)}
          rows={ordersToShow || []}
          hasEditing={true}
          hasRemoving={true}
          setSelectedItem={setSelectedOrder}
          actionsInFirstLine={true}
          colorfulRows={true}
          fitWidth={true}
          height="700px"
        ></Table>
      )
    }
  }

  function Filters() {
    return (
      <OrdersFilters filters={filters} setFilters={setFilters} defaultFilters={defaultFilters}></OrdersFilters>
    )
  }


  return (
    <Container>
      <Header title="Pedidos" subtitle="Visualize e gerencie os pedidos de todas as subsidiárias" />
      <Content>
        <Drawer open={openDrawer} handleOpen={setOpenDrawer} Children={Filters}></Drawer>
        <RowFilters>
          <Title>{titleToShow}</Title>
          <Searchbar
            value={search}
            onChange={setSearch}
            disabled={false}
            width={300}
            placeholder="Buscar pedido por id..."
            fontSize={'0.7rem'}
          ></Searchbar>
          <Button
            label="Adicionar pedido padrão"
            background="#256CE1"
            color="white"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => { setOpenModalAddOrder(true) }}
          ></Button>
          <Button
            label="Adicionar pedido de estoque"
            background="#256CE1"
            color="white"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => { setOpenModalAddOrderStock(true) }}
          ></Button>
          <Button
            label="Adicionar pedido rateado"
            background="#256CE1"
            color="white"
            borderColor="#256CE1"
            disabled={false}
            onClick={() => { setOpenModalAddOrderApportionment(true) }}
          ></Button>
          <Button
            label="Editar colunas"
            background="#54577A"
            color="white"
            borderColor="#54577A"
            disabled={false}
            onClick={() => { setOpenModalChooseColumns(true) }}
          ></Button>
          <Button
            label={filterButtonLabel}
            background="transparent"
            color="#54577A"
            borderColor="#54577A"
            disabled={false}
            onClick={() => setOpenDrawer(true)}
          ></Button>
        </RowFilters>
        <Row>
          <div style={{ height: 16 }}></div>
        </Row>
        <RowContent>
          {renderTable()}
        </RowContent>
        <RowPagination>
          <PaginationComponent totalPages={totalPages} page={filters?.pages} handleChangePage={handleChangePage} />
        </RowPagination>
      </Content>
      {openModalChooseColumns && (
        <ModalChooseColumns
          open={openModalChooseColumns}
          handleOpen={setOpenModalChooseColumns}
          width={600}
          height={700}
          columnsToShow={columnsToShow}
        ></ModalChooseColumns>
      )}
      {selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'visualize' && (
        <ModalVisualizeOrder
          open={selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'visualize'}
          setSelectedItem={setSelectedOrder}
          selectedItem={selectedOrder}
          width={1100}
          height={900}
        ></ModalVisualizeOrder>
      )}
      {openModalAddOrder && (
        <ModalAddOrder
          open={openModalAddOrder}
          handleOpen={setOpenModalAddOrder}
          width={1000}
          height={900}
        />
      )}
      {openModalAddOrderStock && (
        <ModalAddOrderStock
          open={openModalAddOrderStock}
          handleOpen={setOpenModalAddOrderStock}
          width={1000}
          height={900}
        ></ModalAddOrderStock>
      )}
      {openModalAddOrderApportionment && (
        <ModalAddOrderApportionment
          open={openModalAddOrderApportionment}
          handleOpen={setOpenModalAddOrderApportionment}
          width={1000}
          height={900}
        ></ModalAddOrderApportionment>
      )}
      {selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'remove' && !selectedOrder.original_order && (
        <ModalRemoveOrder
          open={selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'remove' && !selectedOrder.original_order}
          setSelectedItem={setSelectedOrder}
          selectedItem={selectedOrder}
          width={600}
          height={220}
          getInfo={getInfo}
        ></ModalRemoveOrder>
      )}
      {selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'edit' && !selectedOrder.isapportionment && !selectedOrder.original_order && !selectedOrder.isstocked && (
        <ModalEditOrder
          open={selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'edit' && !selectedOrder.isapportionment && !selectedOrder.original_order && !selectedOrder.isstocked}
          setSelectedItem={setSelectedOrder}
          selectedItem={selectedOrder}
          width={1000}
          height={900}
        ></ModalEditOrder>
      )}

      {selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'edit' && selectedOrder.isapportionment && (
        <ModalEditOrderApportionment
          open={selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'edit' && selectedOrder.isapportionment}
          setSelectedItem={setSelectedOrder}
          selectedItem={selectedOrder}
          width={1000}
          height={900}
        ></ModalEditOrderApportionment>
      )}
      {selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'edit' && !selectedOrder.isapportionment && !selectedOrder.original_order && selectedOrder.isstocked && (
        <ModalEditOrderStock
          open={selectedOrder !== '' && selectedOrder.open && selectedOrder.mode === 'edit' && !selectedOrder.isapportionment && !selectedOrder.original_order && selectedOrder.isstocked}
          setSelectedItem={setSelectedOrder}
          selectedItem={selectedOrder}
          width={1000}
          height={900}
        ></ModalEditOrderStock>
      )}
    </Container>
  );
};

export default React.createElement(Orders);