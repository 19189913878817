
import { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface User {
    id_user: number;
    user_role: string;
    e_mail: string;
    user_password: string;
    token: string;
    company_name: string;
    fk_companies_id_company: number;
    isactive: boolean;
}
type UserInfo = Omit<User, 'user_password'>
type AuthInput = Omit<User, 'token' | 'user_role' | 'id_user' | 'company_name' | 'fk_companies_id_company' | 'isactive'>
type CreateInput = Omit<User, 'token' | 'id_user' | 'company_name' | 'user_password' | 'isactive'>;
type RegisterInput = Omit<User, 'token' | 'id_user' | 'isactive' | 'fk_companies_id_company' | 'user_role'>;

interface UserPreferences {
    [parametro: string]: boolean;
}

interface UsersProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
    data: any;
}
interface ResponseWithoutDataProps {
    success: boolean;
    message: string;
}

interface UsersContextData {
    users: User[];
    authenticateUser: (user: AuthInput) => Promise<ResponseProps>;
    createUser: (user: CreateInput) => Promise<ResponseProps>;
    registerUser: (user: RegisterInput) => Promise<ResponseWithoutDataProps>;
    getUserPreferences: (id_user: string) => Promise<ResponseProps>;
    setUserPreferences: (id_user: string, columns_preferences: UserPreferences[]) => Promise<ResponseWithoutDataProps>;
}

const UsersContext = createContext<UsersContextData>(
    {} as UsersContextData
);


export function UsersProvider({ children }: UsersProvidersProps) {
    const [users, setUsers] = useState<User[]>([]);
    const [, setUser] = useState<UserInfo>();
    const [tokenAvailable, setTockenAvailable] = useState(false);

    useEffect(() => {
        function handleCustomEvent(event: Event) {
            setTockenAvailable(true);
        }

        window.addEventListener("tokenActive", handleCustomEvent);

        return () => {
            window.removeEventListener("tokenActive", handleCustomEvent);
        };
    }, []);

    useEffect(() => {
        if (!localStorageService.getIdToken()) return;

        api.get('users').then(response => setUsers(response.data))
    }, [tokenAvailable])

    async function authenticateUser(authInput: AuthInput) {
        const response = await api.post('/login', { ...authInput });
        if (response.status === 200 && !response.data.error) {
            const userInfo = {
                id_user: response?.data?.id_user,
                user_role: response?.data?.user_role,
                e_mail: authInput?.e_mail,
                token: response?.data?.token,
                company_name: response?.data?.company_name,
                fk_companies_id_company: response?.data?.fk_companies_id_company,
                isactive: response?.data?.isactive,
            };
            setUser(userInfo);
            return {
                success: true,
                message: "Usuário autenticado com sucesso!",
                data: userInfo,
            }
        }
        return {
            success: false,
            message: response.data && response.data.error ? response.data.error : "Erro ao autenticar usuário.",
            data: {
                id_user: 0,
                user_role: '',
                e_mail: '',
                token: '',
                company_name: '',
                fk_companies_id_company: 0,
                isactive: false,
            },
        }
    }

    async function getUserPreferences(id_user: string) {
        const response = await api.get('/user_preferences', {
            params: { id_user },
        });
        if (response.status === 200 && !response.data.error) {
            const userPreferences = response?.data;
            return {
                success: true,
                message: "Usuário autenticado com sucesso!",
                data: userPreferences,
            }
        }
        return {
            success: false,
            message: response.data && response.data.error ? response.data.error : "Erro ao buscar dados do usuário.",
            data: null
        }
    }


    async function createUser(createInput: CreateInput) {
        let newUser = { ...createInput, new_email: createInput.e_mail };
        const response = await api.post('/user_registration', { ...newUser });
        if (response.status === 200 && !response.data.error) {
            api.get('users').then(response => setUsers(response.data))
            return {
                success: true,
                message: "Usuário pré-cadastrado  com sucesso!",
                data: {
                    id_user: 0,
                    user_role: '',
                    e_mail: '',
                    token: '',
                    company_name: '',
                    fk_companies_id_company: 0,
                    isactive: false,
                },
            }
        }
        return {
            success: false,
            message: response.data && response.data.error ? response.data.error : "Erro ao pré-cadastrar usuário.",
            data: {
                id_user: 0,
                user_role: '',
                e_mail: '',
                token: '',
                company_name: '',
                fk_companies_id_company: 0,
                isactive: false,
            },
        }
    }

    async function registerUser(registerInput: RegisterInput) {
        const response = await api.post('/new_user', { ...registerInput });
        if (response.status === 200 && !response.data.error) {
            return {
                success: true,
                message: "Usuário cadastrado  com sucesso!",
            }
        }
        return {
            success: false,
            message: response.data && response.data.error ? response.data.error : "Erro ao cadastrar usuário.",
        }
    }

    async function setUserPreferences(id_user: string, columns_preferences: UserPreferences[]) {
        const response = await api.post('/user_preferences', { id_user: id_user, columns: columns_preferences });
        if (response.status === 200 && !response.data.error) {
            return {
                success: true,
                message: "Prefências alteradas com sucesso!",
            }
        }
        return {
            success: false,
            message: response.data && response.data.error ? response.data.error : "Erro ao alterar preferências.",
        }
    }



    return (
        <UsersContext.Provider value={{ users, authenticateUser, createUser, registerUser, getUserPreferences, setUserPreferences }}>
            {children}
        </UsersContext.Provider>
    );
}

export function UseUsers() {
    const context = useContext(UsersContext);
    return context;
}