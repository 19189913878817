
import { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface PaymentType {
    id_paymenttype: string;
    paymenttype_name: string;
}
type PaymentTypeInput = Omit<PaymentType, 'id_paymenttype'>

interface PaymentTypesProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface PaymentTypesContextData {
    paymentTypes: PaymentType[];
    createPaymentType: (paymentType: PaymentTypeInput) => Promise<ResponseProps>;
    updatePaymentType: (paymentType: PaymentType) => Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
}

const PaymentTypesContext = createContext<PaymentTypesContextData>(
    {} as PaymentTypesContextData
);


export function PaymentTypesProvider({ children }: PaymentTypesProvidersProps) {
    const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
    const [refreshOnDelete, setRefreshOnDelete] = useState(0);
    const [tokenAvailable, setTockenAvailable] = useState(false);

    useEffect(() => {
        function handleCustomEvent(event: Event) {
            setTockenAvailable(true);
        }

        window.addEventListener("tokenActive", handleCustomEvent);

        return () => {
            window.removeEventListener("tokenActive", handleCustomEvent);
        };
    }, []);

    useEffect(() => {
        if (!localStorageService.getIdToken()) return;

        api.get('paymenttype').then(response => setPaymentTypes(response.data))
    }, [refreshOnDelete, tokenAvailable])

    async function updateOnDelete(id: number) {
        const response = await api.post('/deletepaymenttype', { id_paymentType: id });
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1);
            return {
                success: true,
                message: "Tipo de pagamento removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover tipo de pagamento."
        }
    }

    async function createPaymentType(paymentTypesInput: PaymentTypeInput) {
        const response = await api.post('/paymenttype', paymentTypesInput);
        if (response.status === 200) {
            api.get('paymenttype').then(response => setPaymentTypes(response.data))
            return {
                success: true,
                message: "Tipo de pagamento adicionado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar tipo de pagamento."
        }

    }

    async function updatePaymentType(paymentType: PaymentType) {
        const response = await api.put('/paymenttype', paymentType);
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1)
            return {
                success: true,
                message: "Tipo de pagamento editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar tipo de pagamento."
        }
    }

    return (
        <PaymentTypesContext.Provider value={{ paymentTypes, createPaymentType, updatePaymentType, updateOnDelete }}>
            {children}
        </PaymentTypesContext.Provider>
    );
}

export function UsePaymentTypes() {
    const context = useContext(PaymentTypesContext);
    return context;
}