import axios from "axios";
import LocalStorageService from "./storage";
import { useNavigate } from "react-router-dom";

const localStorageService = LocalStorageService();

axios.defaults.timeout = 30000; // Configurando timeout de 30 segundos

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || "http://localhost:3000/api",
  params: {
    token: localStorageService.getIdToken(),
  },
});

api.interceptors.request.use(
  (config) => {
    const tokenToUse = localStorageService.getIdToken();

    if (!config.params.token) {
      config.params = {
        token: tokenToUse,
      };
    }
    config.data = {
      ...config.data,
      token: tokenToUse,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (err) => {
    // const originalRequest = err.config;
    console.log("Erro:", err.response);

    if (err?.response?.status === 401) {
      localStorageService.logout();
      let navigate = useNavigate();
      navigate("/");
    }

    return err.response;
  }
);

export default api;
