
import { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface Boat {
    id_boat: string;
    boats_name: string;
    rgp: string;
    permissions: boolean;
    validity: string;
    length: number;
    weigth: number;
    engine_1: string;
    engine_2: string;
    engine_3: string;
}

type BoatInput = Omit<Boat, 'id_boat'>

interface FilterInput {
    filter: string;
    value: string;
}

interface BoatsProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface BoatsContextData {
    boats: Boat[];
    createBoat: (boat: BoatInput) => Promise<ResponseProps>;
    updateBoat: (boat: Boat) => Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
    filterBoats: (filter: FilterInput) => Promise<void>;
}

const BoatsContext = createContext<BoatsContextData>(
    {} as BoatsContextData
);


export function BoatsProvider({ children }: BoatsProvidersProps) {
    const [boats, setBoats] = useState<Boat[]>([]);
    const [refreshOnDelete, setRefreshOnDelete] = useState(0);
    const [tokenAvailable, setTockenAvailable] = useState(false);

    useEffect(() => {
        function handleCustomEvent(event: Event) {
            setTockenAvailable(true);
        }

        window.addEventListener("tokenActive", handleCustomEvent);

        return () => {
            window.removeEventListener("tokenActive", handleCustomEvent);
        };
    }, []);

    useEffect(() => {
        if (!localStorageService.getIdToken()) return;

        api.get('boats')
            .then(response => setBoats(response.data))
    }, [refreshOnDelete, tokenAvailable])

    async function updateOnDelete(id: number) {
        const response = await api.delete('/boats', { data: { id: id } });
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1);
            return {
                success: true,
                message: "Barco removido com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover barco."
        }
    }


    async function createBoat(boatInput: BoatInput) {
        const response = await api.post('/boats', boatInput)
        if (response.status === 200 && !response.data.error) {
            api.get('boats').then(response => setBoats(response.data))
            return {
                success: true,
                message: "Barco adicionado com sucesso!"
            }
        }
        return {
            success: false,
            message: response?.data.error || "Erro ao adicionar barco."
        }

    }

    async function filterBoats(filter: FilterInput) {
        api.post('boats/filter', { filter: 'permissions', value: 'Sim' })
            .then(response => setBoats(response.data));
    }

    async function updateBoat(boat: Boat) {
        const response = await api.put('/boats', boat);
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1)
            return {
                success: true,
                message: "Barco editado com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar barco."
        }
    }

    return (
        <BoatsContext.Provider value={{ boats, createBoat, filterBoats, updateBoat, updateOnDelete }}>
            {children}
        </BoatsContext.Provider>
    );
}

export function UseBoats() {
    const context = useContext(BoatsContext);

    return context;
}