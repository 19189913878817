
import { createContext, useEffect, useState, ReactNode, useContext } from 'react';
import api from '../services/api';
import LocalStorageService from '../services/storage';

const localStorageService = LocalStorageService();

interface Subsidiary {
    id_subsidiary: string;
    subsidiary_name: string;
    cnpj: string;
}
type SubsidiaryInput = Omit<Subsidiary, 'id_subsidiary'>

interface SubsidiariesProvidersProps {
    children: ReactNode;
}

interface ResponseProps {
    success: boolean;
    message: string;
}

interface SubsidiariesContextData {
    subsidiaries: Subsidiary[];
    createSubsidiary: (subsidiary: SubsidiaryInput) => Promise<ResponseProps>;
    updateSubsidiary: (subsidiary: Subsidiary) => Promise<ResponseProps>;
    updateOnDelete: (id: number) => Promise<ResponseProps>;
}

const SubsidiariesContext = createContext<SubsidiariesContextData>(
    {} as SubsidiariesContextData
);


export function SubsidiariesProvider({ children }: SubsidiariesProvidersProps) {
    const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>([]);
    const [refreshOnDelete, setRefreshOnDelete] = useState(0);
    const [tokenAvailable, setTockenAvailable] = useState(false);

    useEffect(() => {
        function handleCustomEvent(event: Event) {
            setTockenAvailable(true);
        }

        window.addEventListener("tokenActive", handleCustomEvent);

        return () => {
            window.removeEventListener("tokenActive", handleCustomEvent);
        };
    }, []);

    useEffect(() => {
        if (!localStorageService.getIdToken()) return;

        api.get('subsidiaries').then(response => setSubsidiaries(response.data))
    }, [refreshOnDelete, tokenAvailable])

    async function updateOnDelete(id: number) {
        const response = await api.post('/deletesubsidiary', { id_subsidiary: id });
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1);
            return {
                success: true,
                message: "Subsidiária removida com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao remover subsidiária."
        }
    }

    async function createSubsidiary(subsidiariesInput: SubsidiaryInput) {
        const response = await api.post('/subsidiaries', subsidiariesInput);
        if (response.status === 200) {
            api.get('subsidiaries').then(response => setSubsidiaries(response.data))
            return {
                success: true,
                message: "Subsidiária adicionada com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao adicionar subsidiária."
        }

    }

    async function updateSubsidiary(subsidiary: Subsidiary) {
        const response = await api.put('/subsidiaries', subsidiary);
        if (response.status === 200) {
            setRefreshOnDelete(oldKey => oldKey + 1)
            return {
                success: true,
                message: "Subsidiária editada com sucesso!"
            }
        }
        return {
            success: false,
            message: "Erro ao editar subsidiária."
        }
    }

    return (
        <SubsidiariesContext.Provider value={{ subsidiaries, createSubsidiary, updateSubsidiary, updateOnDelete }}>
            {children}
        </SubsidiariesContext.Provider>
    );
}

export function UseSubsidiaries() {
    const context = useContext(SubsidiariesContext);
    return context;
}